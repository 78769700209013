import React, { useState, useRef, useEffect } from "react";
import { Carousel, Popover } from "antd";
import {
  PhoneFilled,
  LeftOutlined,
  RightOutlined,
  CheckCircleFilled
} from "@ant-design/icons";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import { headerData, info, banner, productData, solutionData } from "./data";
import Page1 from "./Page1";
import styles from "./Index.module.scss";
import "./home.css";
import { getToken } from "../myutils/user";

function HomePage() {
  const couterRef = useRef();
  const [activeIndex, setActiveIndex] = useState();
  const changeTab = num => {
    return () => {
      setActiveIndex(num);
    };
  };
  const getScrollTop = () => {
    let scrollTop = 0;

    if (document.documentElement && document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop;
    } else if (document.body) {
      scrollTop = document.body.scrollTop;
    }

    return scrollTop;
  };
  useEffect(() => {
    window.onscroll = function() {
      const topNum = getScrollTop();
      if (topNum < 600) {
        setActiveIndex(0);
      } else if (topNum > 600 && topNum < 1600) {
        setActiveIndex(1);
      } else if (topNum > 1700 && topNum < 2700) {
        setActiveIndex(2);
      } else if (topNum > 2700 && topNum < 3400) {
        setActiveIndex(3);
      } else if (topNum > 3400 && topNum < 4150) {
        setActiveIndex(4);
      } else if (topNum > 4150 && topNum < 4700) {
        setActiveIndex(5);
      } else if (topNum > 4700) {
        setActiveIndex(6);
      }
    };
  }, []);
  const getProductDom = x => {
    const data = x.lData.type === 1 ? "lData" : "rData";
    const data2 = x.lData.type === 2 ? "lData" : "rData";
    return (
      <div className="box clearfix">
        <div className={x[data].name}>
          <h3>
            {x[data].title}
            <br />
            {x[data].dec}
          </h3>
          <hr />
          <p> {x[data].dec2}</p>
          <a href={x.src} target="_blank">
            了解更多
          </a>
        </div>
        <img src={x[data2].src} alt="产品插图" className={x[data2].name} />
      </div>
    );
  };
  return (
    <div className="faBox">
      <header className="homeTop clearfix">
        <div className="center">
          <img src="/img/denglulogo.png" alt="" className="left" />
          <div className="letf leftBox">
            {headerData.map((x, index) => (
              <a
                href={x.oHref}
                onClick={changeTab(index)}
                className={activeIndex === index && "active"}
              >
                {x.title}
              </a>
            ))}
            <div className="box">
              <PhoneFilled className="phone" />
              <span>{info.tel}</span>
              <span className="gray">|</span>
              <a href={info.loginUrl} className="loginBtn">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                登录 &gt;
              </a>
            </div>
          </div>
        </div>
      </header>
      <div style={{ paddingTop: 74 }} />
      <div id="banner">
        <Carousel autoplay>
          {banner.map(x => {
            return (
              <div className="banner">
                <img src={x.bgSrc} />
                <div className="center">
                  <h1>{x.title}</h1>
                  <h3>{x.des}</h3>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <a href="#contact">立即咨询 &gt;</a>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
      <div id="product">
        <div className="center">
          <h1>产品家族</h1>
          <OverPack>
            <QueueAnim key="queue2" type="bottom" leaveReverse>
              {productData.map(x => getProductDom(x))}
            </QueueAnim>
          </OverPack>
        </div>
      </div>
      <div id="solution" className="clearfix">
        <div className="center" dotPosition="left" dots>
          <h1>解决方案</h1>
          <Carousel autoplay ref={couterRef}>
            {solutionData.map(x => {
              return (
                <div className="pBox">
                  {x.data.map(y => {
                    return (
                      <div className="box">
                        <div className="topBox">
                          <h3>{y.title}</h3>
                          <p title={y.dec}>{y.dec}</p>
                        </div>
                        <div className="bottom">
                          <div className="st clearfix">
                            <img
                              src="/img/type.png"
                              alt=""
                              className="left biao"
                            />
                            <div className="left sp">
                              <h3>{y.title2}</h3>
                              {y.solve.map(z => (
                                <p>{z}</p>
                              ))}
                            </div>
                          </div>
                          <div className="st clearfix">
                            <img
                              src="/img/type2.png"
                              alt=""
                              className="left biao"
                            />
                            <div className="left sp">
                              <h3>{y.title3}</h3>
                              {y.chara.map(z => (
                                <p>{z}</p>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Carousel>
          <div
            className="leftCarou carou"
            onClick={() => {
              couterRef.current.slick.slickPrev();
            }}
          >
            <LeftOutlined />
          </div>
          <div
            className="rightCarou carou"
            onClick={() => {
              couterRef.current.slick.slickNext();
            }}
          >
            <RightOutlined />
          </div>
        </div>
      </div>
      <div id="our">
        <div className="center">
          <Page1 />
        </div>
      </div>
      <div className={styles.container}>
        <section className={styles.section7} id="try">
          <div className={styles.center}>
            <h2>免费使用</h2>
            <div className="center" style={{ width: 900 }}>
              <div className={`${styles.poster} ${styles.free}`}>
                <div className={styles.header}>
                  <h3>免费版</h3>
                  <div className={styles.iconBox}>
                    <img
                      src="/img/dollar.png"
                      width="34"
                      height="34"
                      alt="免费版"
                    />
                  </div>
                </div>
                <div className={styles.body}>
                  <ul>
                    <li>
                      <CheckCircleFilled
                        style={{ color: "#15E19C", marginRight: "10px" }}
                      />
                      完全免费
                    </li>
                    <li>
                      <CheckCircleFilled
                        style={{ color: "#15E19C", marginRight: "10px" }}
                      />
                      功能全开放
                    </li>
                    <li>
                      <CheckCircleFilled
                        style={{ color: "#15E19C", marginRight: "10px" }}
                      />
                      容量无限制
                    </li>
                    <li>
                      <CheckCircleFilled
                        style={{ color: "#15E19C", marginRight: "10px" }}
                      />
                      专业人工培训
                    </li>
                    <li>
                      <CheckCircleFilled
                        style={{ color: "#15E19C", marginRight: "10px" }}
                      />
                      实时在线客服
                    </li>
                  </ul>
                </div>
                <a href="/login">
                  <div className={styles.btn}>立即注册</div>
                </a>
              </div>
              <div className={`${styles.poster} ${styles.vip}`}>
                <div className={styles.header}>
                  <h3>付费定制版</h3>
                  <div className={styles.iconBox}>
                    <img
                      src="/img/shopping.png"
                      width="34"
                      height="34"
                      alt="付费定制版"
                    />
                  </div>
                </div>
                <div className={styles.body}>
                  <ul>
                    <li>
                      <CheckCircleFilled
                        style={{ color: "#FFCD3E", marginRight: "10px" }}
                      />
                      自定义域名
                    </li>
                    <li>
                      <CheckCircleFilled
                        style={{ color: "#FFCD3E", marginRight: "10px" }}
                      />
                      自定义logo
                    </li>
                    <li>
                      <CheckCircleFilled
                        style={{ color: "#FFCD3E", marginRight: "10px" }}
                      />
                      自定义系统名称
                    </li>
                    <li>
                      <CheckCircleFilled
                        style={{ color: "#FFCD3E", marginRight: "10px" }}
                      />
                      私有云部署
                    </li>
                    <li>
                      <CheckCircleFilled
                        style={{ color: "#FFCD3E", marginRight: "10px" }}
                      />
                      VPN安全访问
                    </li>
                  </ul>
                </div>
                <a href="#contact">
                  <div className={styles.btn}>联系我们</div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <div id="contact" className={styles.contact}>
          <div className={styles.title}>联系方式</div>
          <div className={styles.content}>
            <div className={styles.box}>
              <Popover
                // trigger="click"
                className="pops"
                style={{ boxShadow: "none", border: "none" }}
                content={
                  <img
                    src="/img/qr2.jpg"
                    alt=""
                    style={{ width: 84, height: 84 }}
                  />
                }
                title=""
              >
                <img
                  src="/img/contact1.png"
                  alt=""
                  style={{ width: 84, height: 84 }}
                />
              </Popover>
              <div className={styles.BoxTip1}>微信号</div>
              <div className={styles.BoxTip2}>oliverhdh</div>
            </div>
            <div className={styles.box}>
              <img
                src="/img/contact2.png"
                alt=""
                style={{ width: 84, height: 84 }}
              />
              <div className={styles.BoxTip1}>联系电话</div>
              <div className={styles.BoxTip2}>010-57135685</div>
            </div>
            <div className={styles.box}>
              <img
                src="/img/contact3.png"
                alt=""
                style={{ width: 84, height: 84 }}
              />
              <div className={styles.BoxTip1}>联系地址</div>
              <div className={styles.BoxTip2}>北京市海淀区</div>
            </div>
            <div className={styles.box}>
              <img
                src="/img/contact4.png"
                alt=""
                style={{ width: 84, height: 84 }}
              />
              <div className={styles.BoxTip1}>联系邮箱</div>
              <div className={styles.BoxTip2}>contact@survey.work</div>
            </div>
          </div>
        </div>
        <section id="customer" className={styles.section4}>
          <div className={`${styles.center} ${styles.flexCenter}`}>
            <div style={{ width: "100%" }}>
              <h2>合作客户</h2>
              <div>
                <div className={styles.partner}>
                  <img
                    width="70"
                    height="70"
                    alt="政府机关"
                    src="/img/goverment.png"
                  />
                  <h4>政府机关</h4>
                  <div className={styles.line} />
                  <ul>
                    <li>
                      <span>创建文明城市</span>
                    </li>
                    <li>
                      <span>城市管理接拆即办</span>
                    </li>
                    <li>
                      <span>营商环境评估</span>
                    </li>
                    <li>
                      <span>公共服务调查</span>
                    </li>
                    <li>
                      <span>垃圾分类督察</span>
                    </li>
                  </ul>
                </div>
                <div className={styles.partner}>
                  <img
                    width="70"
                    height="70"
                    alt="企业客户"
                    src="/img/company.png"
                  />
                  <h4>企业客户</h4>
                  <div className={styles.line} />
                  <ul>
                    <li>
                      <span>运营质量监测</span>
                    </li>
                    <li>
                      <span>客户满意度调查</span>
                    </li>
                    <li>
                      <span>服务规范培训</span>
                    </li>
                    <li>
                      <span>产品可行性调查</span>
                    </li>
                    <li>
                      <span>明察暗访</span>
                    </li>
                  </ul>
                </div>
                <div className={styles.partner}>
                  <img
                    width="70"
                    height="70"
                    alt="市场研究机构"
                    src="/img/market.png"
                  />
                  <h4>市场研究机构</h4>
                  <div className={styles.line} />
                  <ul>
                    <li>
                      <span>市场调查分析</span>
                    </li>
                    <li>
                      <span>用户行为研究</span>
                    </li>
                    <li>
                      <span>产品研究</span>
                    </li>
                    <li>
                      <span>行业分析</span>
                    </li>
                    <li>
                      <span>实地巡查</span>
                    </li>
                  </ul>
                </div>
                <div className={styles.partner}>
                  <img
                    width="70"
                    height="70"
                    alt="市场研究机构"
                    src="/img/edu.png"
                  />
                  <h4>科研教育机构</h4>
                  <div className={styles.line} />
                  <ul>
                    <li>
                      <span>学术科研调查</span>
                    </li>
                    <li>
                      <span>课题调研</span>
                    </li>
                    <li>
                      <span>社会调查</span>
                    </li>
                    <li>
                      <span>教育应用研究</span>
                    </li>
                    <li>
                      <span>新闻调查</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.footer}>
          <div className={styles.content}>
            <div className={styles.item1}>
              <img src="/img/logif.png" style={{ width: 150 }} />
            </div>
            <div className={styles.item}>
              <p className={styles.bigTitle}>友情链接</p>
              <a href={productData[0].src} target="_blank">
                {productData[0].lData.title}
              </a>
              <a href={productData[1].src} target="_blank">
                {productData[1].rData.title}
              </a>
              <a href={productData[2].src} target="_blank">
                {productData[2].lData.title}
              </a>
            </div>
            <div className={styles.item}>
              <p className={styles.bigTitle}>联合开发</p>
              <p>首都网络文明研究基地</p>
              <p>清华大学河北发展研究院</p>
              <p>南京大学网络传播研究院</p>
            </div>
            <div className={styles.item}>
              <p className={styles.bigTitle}>联系我们</p>
              <p>电话：{info.tel}</p>
              <p>邮箱：{info.mail}</p>
              <p>微信：{info.wx}</p>
            </div>
            <div className={styles.item}>
              <img
                src="/img/qr2.jpg"
                alt=""
                style={{ width: 130, marginTop: 20 }}
              />
            </div>
          </div>
        </div>
        <footer className={styles.spFooter}>
          <a href="http://www.beian.miit.gov.cn/">京ICP备13048388号-18</a>
        </footer>
      </div>
    </div>
  );
}

export default HomePage;
