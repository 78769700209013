import React, { useEffect } from "react";

export default function ImageCode({ setCode }) {
  const canvas = React.createRef();
  const state = {
    code: "",
    codeLength: 4,
    fontSizeMin: 22,
    fontSizeMax: 26,
    backgroundColorMin: 240,
    backgroundColorMax: 250,
    colorMin: 0,
    colorMax: 100,
    lineColorMin: 40,
    lineColorMax: 180,
    contentWidth: 96,
    contentHeight: 38,
    showError: false // 默认不显示验证码的错误信息
  };
  // 生成一个随机数
  const randomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  };
  // 生成一个随机的颜色
  const randomColor = (min, max) => {
    const r = randomNum(min, max);
    const g = randomNum(min, max);
    const b = randomNum(min, max);
    return `rgb(${r}, ${g}, ${b})`;
  };
  const drawText = (ctx, txt, i) => {
    ctx.fillStyle = randomColor(state.colorMin, state.colorMax);
    const fontSize = randomNum(state.fontSizeMin, state.fontSizeMax);
    ctx.font = fontSize + "px SimHei";
    const padding = 10;
    const offset = (state.contentWidth - 40) / (state.code.length - 1);
    console.log(offset);
    let x = padding;
    if (i > 0) {
      x = padding + i * offset;
    }
    let y = randomNum(state.fontSizeMax, state.contentHeight - 5);
    if (fontSize > 40) {
      y = 40;
    }
    const deg = randomNum(-10, 10);
    // 修改坐标原点和旋转角度
    ctx.translate(x, y);
    ctx.rotate((deg * Math.PI) / 180);
    ctx.fillText(txt, 0, 0);
    // 恢复坐标原点和旋转角度
    ctx.rotate((-deg * Math.PI) / 180);
    ctx.translate(-x, -y);
  };

  const drawLine = ctx => {
    // 绘制干扰线
    for (let i = 0; i < 1; i++) {
      ctx.strokeStyle = randomColor(state.lineColorMin, state.lineColorMax);
      ctx.beginPath();
      ctx.moveTo(
        randomNum(0, state.contentWidth),
        randomNum(0, state.contentHeight)
      );
      ctx.lineTo(
        randomNum(0, state.contentWidth),
        randomNum(0, state.contentHeight)
      );
      ctx.stroke();
    }
  };

  const drawDot = ctx => {
    // 绘制干扰点
    for (let i = 0; i < 100; i++) {
      ctx.fillStyle = randomColor(0, 255);
      ctx.beginPath();
      ctx.arc(
        randomNum(0, state.contentWidth),
        randomNum(0, state.contentHeight),
        1,
        0,
        2 * Math.PI
      );
      ctx.fill();
    }
  };

  // const reloadPic = () => {
  //   drawPic();
  // };

  // 随机生成验证码
  const randomCode = () => {
    let random = "";
    // 去掉了I l i o O,可自行添加
    const str = "QWERTYUPLKJHGFDSAZXCVBNMqwertyupkjhgfdsazxcvbnm1234567890";
    for (let i = 0; i < state.codeLength; i++) {
      const index = Math.floor(Math.random() * 57);
      random += str[index] + " ";
    }
    const canvasObj = canvas.current;
    const ctx = canvasObj.getContext("2d");
    ctx.textBaseline = "bottom";
    // 绘制背景
    ctx.fillStyle = randomColor(
      state.backgroundColorMin,
      state.backgroundColorMax
    );
    ctx.fillRect(0, 0, state.contentWidth, state.contentHeight);
    setCode(random.replace(/\s*/g, ""));
    // 绘制文字
    drawText(ctx, random, 0);
    drawLine(ctx);
    drawDot(ctx);
  };
  useEffect(() => {
    randomCode();
  }, []);

  return (
    <a>
      <canvas
        onClick={() => {
          randomCode();
        }}
        ref={canvas}
        width="100"
        height="36"
        title="点击更换"
      />
    </a>
  );
}
