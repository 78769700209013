const domain = window.location.hostname;
const baseUrlTest =
  "https://" + domain === "https://test-front-base.survey.work"
    ? "https://testcloud.treeyee.com"
    : "https://test.diaoyan001.com";
const baseUrlProd =
  "https://" + domain === "https://survey.work"
    ? "https://wj.survey.work"
    : "https://diaoyan001.com";

export const BasicUrl = {
  test:
    domain === "test-front-base.survey.work"
      ? "https://test-front-base.survey.work"
      : "https://test.diaoyan001.com",
  production:
    domain === "survey.work" ? "https://survey.work" : "https://diaoyan001.com"
};
export const WenJuanUrl = {
  test: baseUrlTest + "/entrance",
  production: baseUrlProd + "/entrance"
};
export const DingZhiWenJuanUrl = {
  test: "https://dingzhi-anyang.survey.work/entrance",
  production: "https://dingzhi-anyang.survey.work/entrance"
};
export const WenJuanRootUrl = {
  test: baseUrlTest,
  production: baseUrlProd
};
export const WenJuanBmsUrl = {
  test:
    "http://dygcbms.c952463b958c1435894b4e05e6b6d0189.cn-beijing.alicontainer.com",
  production: "https://dygcbms-rc.survey.work"
};
