import React, { lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import "./App.css";
import "./styles/common.scss";
import ScreenLoadingSuspense from "./pages/components/screenLoadingSuspense";
import CompanySettingContextWrapped from "./pages/context/companySetting";
import Home from "./pages/home/home";
import DownCallBack from "./pages/home/downCallBack";
import phoneRouter from "./pages/phone/router";

const Login = lazy(() => import("./pages/login"));
const NewLogin = lazy(() => import("./pages/newLogin"));
const Index = lazy(() => import("./pages/index"));
const PhoneWallet = lazy(() => import("./pages/user/phoneWallet/walletList"));
const PhoneDetailedList = lazy(() =>
  import("./pages/user/phoneWallet/detailedList")
);
const Authentication = lazy(() =>
  import("./pages/index/components/authentication/company")
);
const Evaluate = lazy(() => import("./pages/index/components/evaluate"));
const VisitorCertify = lazy(() =>
  import("./pages/index/components/visitorCertify")
);
const SamplePushDetails = lazy(() =>
  import("./pages/index/components/samplePush")
);
const Sample = lazy(() => import("./pages/manager/user/userList/sample"));
const PrivacyPolicy = lazy(() => import("./pages/agreeMent/privacyPolicy"));
const PrivacyPolicyInvestigator = lazy(() =>
  import("./pages/agreeMent/privacyPolicyInvestigator")
);
const undertakeTasksPolicy = lazy(() =>
  import("./pages/agreeMent/undertakeTasksPolicy")
);
const realNameAuth = lazy(() =>
  import("./pages/agreeMent/realNameAuth")
);
const displayDataPolicy = lazy(() =>
  import("./pages/agreeMent/displayDataPolicy")
);
const Visitor = lazy(() => import("./pages/user/workbench/home/visitor"));
const UserPolicy = lazy(() => import("./pages/agreeMent/userPolicy"));
const MaskSquareUserPolicy = lazy(() =>
  import("./pages/agreeMent/maskSquareUserPolicy")
);
const Update = lazy(() => import("./pages/home/update"));

function App() {
  return (
    <>
      {/* 落地页 */}
      <Route exact path="/home" component={Home} />
      <Route
        exact
        path="/"
        render={({ location }) => {
          // if (location.search) {
          //   return <Redirect to={{ ...location, pathname: "/new" }} />;
          // }
          // return location.search ? (
          //   <Redirect to={{ ...location, pathname: "/index" }} />
          // ) : (
          //   <Redirect to={{ ...location, pathname: "/survey" }} />
          // );
          return <Redirect to={{ ...location, pathname: "/newlogin" }} />;
        }}
      />
      {/* 兼容定制企业首页路由/user/home */}
      <Route
        exact
        path="/user/home"
        render={({ location }) => {
          // if (location.search) {
          //   return <Redirect to={{ ...location, pathname: "/index" }} />;
          // }
          // return location.search ? (
          //   <Redirect to={{ ...location, pathname: "/index" }} />
          // ) : (
          //   <Redirect to={{ ...location, pathname: "/survey" }} />
          // );newlogin
          return <Redirect to={{ ...location, pathname: "/newlogin" }} />;
        }}
      />
      <ScreenLoadingSuspense>
        <CompanySettingContextWrapped>
          <Switch>
            <Route
              exact
              path="/(login|register|mymymanager)"
              component={Login}
            />
            <Route
              exact
              path="/(newlogin|newregister|newmymymanager)"
              component={NewLogin}
            />
            {/* <Route exact path="/survey" component={Update} /> */}
            <Route exact path="/systemcare" component={Update} />
            {/* 下载器回调页面 */}
            <Route exact path="/downCallBack" component={DownCallBack} />
            {/* H5页面 */}
            <Route exact path="/user/phoneWallet" component={PhoneWallet} />
            {/* 隐私政策 */}
            <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
            {/* 隐私政策 */}
            <Route
              exact
              path="/privacyPolicyInvestigator"
              component={PrivacyPolicyInvestigator}
            />
            <Route
              exact
              path="/undertakeTasksPolicy"
              component={undertakeTasksPolicy}
            />
            <Route
              exact
              path="/realNameAuth"
              component={realNameAuth}
            />

            {/* 数据广场用户内容上架发布协议 */}
            <Route
              exact
              path="/displayDataPolicy"
              component={displayDataPolicy}
            />
            {/* 用户协议 */}
            {/* <Route exact path="/userPolicy" component={UserPolicy} /> */}
            <Route exact path="/userPolicy" component={UserPolicy} />
            {/* 认证 pageType 0 个人  1 企业  noBack 传值代表不要返回 */}
            <Route exact path="/authentication" component={Authentication} />
            {/* 访问员库 给其他吧用  pageType 代表别的吧 */}
            <Route exact path="/visitor" component={Visitor} />
            {/* 评价 给其他吧用 */}
            <Route exact path="/evaluate" component={Evaluate} />
            {/* 访问员认证 给任务广场调用 */}
            <Route exact path="/visitorcertify" component={VisitorCertify} />
            {/* 样本推送 */}
            <Route
              exact
              path="/samplePushDetails"
              component={SamplePushDetails}
            />
            {/* 样本明暗查询 */}
            <Route exact path="/sampleQuery" component={Sample} />
            {/* 调研工厂任务广场用户注册服务协议 */}
            <Route
              exact
              path="/maskSquareUserPolicy"
              component={MaskSquareUserPolicy}
            />
            <Route
              exact
              path="/user/detailedList"
              component={PhoneDetailedList}
            />
            {/* H5系列 */}
            {phoneRouter.map(x => {
              return (
                <Route
                  key={x.path}
                  exact
                  path={x.path}
                  component={x.component}
                />
              );
            })}
            {/* <Route exact path="*" component={Four} /> */}
            <Route path="/:group/:member?" component={Index} />
          </Switch>
        </CompanySettingContextWrapped>
      </ScreenLoadingSuspense>
    </>
  );
}

export default App;
