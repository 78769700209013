/* eslint-disable no-redeclare */
/* eslint-disable no-shadow */
// 课程状态
import React, {
  useState,
  useEffect,
  lazy,
  useRef,
  useImperativeHandle,
  forwardRef
} from "react";
import { Tree, Modal, Button, Form, Input, message } from "antd";
import { showMessage, baseUrl, AWSCValidate } from "utils";
import {
  PlusCircleOutlined,
  FormOutlined,
  DeleteOutlined,
  UpCircleOutlined,
  DownCircleOutlined
} from "@ant-design/icons";
// import { assignStyleCompanyId } from "apis/user";
import ImageCode from "pages/components/ImageCode";

import cookies from "js-cookie";
import * as qs from "query-string";
import moment from "moment";
import { BasicUrl } from "config/config";
import "../styles/common.scss";

const { TreeNode } = Tree;
const time = new Date();
const t = moment(time).valueOf();
/**
 * 处理删除之类的操作
 * @param content  弹出提示的内容 （必填）
 * @param fn  调取关于删除api函数 （必填）
 * @param params  删除api的参数 （必填）
 * @param searchData 回显api的参数 （必填）
 * @param getData  回显api返回的数据 （必填）
 * @param callback 回显api函数 （必填）
 * @param init  分页pageNumber初始值  （不必填）
 * @param pageNumber  分页参数一般是pageNumber 也有的是page 变量  （不必填）
 * @param selectedRowKeys  批量选择删除时，判断选择是不是全选问题  （不必填）
 * 事例： src/Train/Platform/manger/manger
 */
export const delFn = (
  content,
  fn,
  params,
  searchData,
  getData,
  callback,
  init,
  pageNumber,
  selectedRowKeys
) => {
  pageNumber = pageNumber || "pageNumber";
  init = init !== "undefined" ? init : 1;
  selectedRowKeys = selectedRowKeys || [];
  console.log(selectedRowKeys);
  Modal.confirm({
    title: "提示",
    content,
    onOk: () => {
      fn(params).then(() => {
        const data = JSON.parse(JSON.stringify(searchData));
        showMessage("success", "删除成功");
        if (getData.length === 1 || selectedRowKeys.length === 10) {
          if (data[pageNumber] !== init) {
            data[pageNumber] = --data[pageNumber];
            callback(data);
          } else {
            callback();
          }
        } else {
          callback();
        }
      });
    },
    onCancel: () => {
      console.log("cancel");
    }
  });
};
// 树分析
export const loop = (data = []) =>
  data.map(item => {
    if (item.childList && item.childList.length) {
      return (
        <TreeNode
          key={item.id}
          title={`${
            // eslint-disable-next-line no-nested-ternary
            item.nature === 2 ? "【门店】" : item.nature === 1 ? "【部门】" : ""
          }${item.name}`}
          value={item.id}
          all={item}
          disabled={item.disabled}
        >
          {loop(item.childList)}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        key={item.id}
        title={`${
          // eslint-disable-next-line no-nested-ternary
          item.nature === 2 ? "【门店】" : item.nature === 1 ? "【部门】" : ""
        }${item.name}`}
        value={item.id}
        all={item}
        isLeaf
        disabled={item.disabled}
        childrenLen={item.childList ? item.childList.length : ""}
      />
    );
  });
// 树分析(同时显示菜单的数字)
export const loopsNum = (data = []) =>
  data.map(item => {
    if (item.childList && item.childList.length) {
      return (
        <TreeNode
          key={item.id}
          title={`${item.name} (${item.number})`}
          value={item.id}
          all={item}
        >
          {loopsNum(item.childList)}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        key={item.id}
        title={`${item.name} (${item.number})`}
        value={item.id}
        all={item}
        isLeaf
        childrenLen={item.childList ? item.childList.length : 0}
      />
    );
  });
// 获取地址栏参数
export const GetRequest = queryName => {
  const query = decodeURI(window.location.search.substring(1));
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] === queryName) {
      return pair[1];
    }
  }
  return null;
};
/**
 * 数据表格操作
 * @param val 插件传的参数 （必填）
 * @param searchData  搜索参数 （必填）
 * @param setSearchData  设置搜索参数函数 （必填）
 * @param getList  获取表格数据函数 （必填）
 */
export const changeTable = (val, searchData, setSearchData, getList) => {
  const data = JSON.parse(JSON.stringify(searchData));
  data.page = val[0] && val[0].current - 1;
  // data.page = 0;

  // eslint-disable-next-line no-restricted-globals
  console.log(isNaN(data.page));
  if (val[1] && Object.keys(val[1]).length !== 0) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const x in val[1]) {
      data[x] = val[1][x] && val[1][x].join(",");
    }
  }
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(data.page)) {
    data.page = 0;
  }
  console.log(data);
  getList(data);
  setSearchData(data);
};
/**
 * 表格重置操作函数
 * @param data 默认数据 一般是searchData初始设置值 （必填）
 * @param setSearchData 设置搜索参数函数 （必填）
 * @param getList 获取表格数据函数 （必填）
 * @param setResetTable （不必填） 如果表格中有过滤的方法需要传 ，
 * 需要设置添加const [resetTable, setResetTable] = useState(true);
 * 然后表格组件前 添加restTable 判断  例子：manager/company/companyList/index
 */
export const reset = (data, setSearchData, getList, setResetTable) => {
  console.log(data);
  setSearchData(data);
  getList(data);
  if (setResetTable) {
    setResetTable(false);
    setTimeout(() => {
      setResetTable(true);
    }, 100);
  }
};
/**
 * 表格搜索操作函数
 * @param searchData 搜索参数 （必填）
 * @param getList 获取表格数据函数 （必填）
 * @param setSearchData 设置搜索参数函数 （必填）
 */
export const search = (searchData, getList, setSearchData) => {
  const data = JSON.parse(JSON.stringify(searchData));
  data.page = 0;
  setSearchData(data);
  getList(data);
};

/**
 * 时间戳转换为 年-月-日 时-分-秒 函数
 * @param {*} timestamp 时间戳参数
 */

export const timestampToTime = timestamp => {
  const date = new Date(timestamp); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + "-";
  const M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  const D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  const h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  const m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  const s =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

  const strDate = Y + M + D + " " + h + m + s;

  return strDate;
};

/**
 * 将后端传的分转换为元
 * @param money 分
 */
export const transformMoney = money => {
  const yuan = (money / 100).toFixed(2);
  // return yuan;
  return parseFloat(yuan).toLocaleString();
};

/**
 * 注册登录页-校验码弹窗, 并发送验证码
 * @param {*} param0
 * @returns
 */
export function VerifyCodeModel({
  visible,
  onClose,
  send,
  username,
  url,
  params,
  codeType
}) {
  console.log(send, username, url, params, codeType);
  const [form] = Form.useForm();
  const [code, setCode] = useState(null);
  const [resetCode, setresetCode] = useState(true);
  const [dis, setDis] = useState(false);
  const [num, setNum] = useState(0);
  const [loading, setLoading] = useState(false);
  const msg = dis ? `${num}s后重发` : "获取短信验证码";
  useEffect(() => {
    let id;
    if (dis) {
      if (num > 0) {
        id = setTimeout(() => {
          setNum(prev => prev - 1);
        }, 1000);
      } else {
        setDis(false);
        setNum(0);
      }
    }
    return () => {
      clearTimeout(id);
    };
  }, [dis, num]);

  const onSubmit = values => {
    setLoading(true);
    window.axios
      .get(url || "/customer/code", {
        params: params || {
          username,
          effect: cookies.get("dingzhiBol"),
          codeType
        }
      })
      .then(({ status, data: { success, message } }) => {
        // console.log('response:', arguments[0])
        if (status !== 200 || !success) {
          showMessage("error", message);
          return;
        }
        setNum(60);
        setDis(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title="请输入校验码"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        // {...formItemLayout}
        form={form}
        onFinish={onSubmit}
        // className={className}
        validateTrigger="onBlur"
      >
        <Form.Item
          name="code"
          style={{ marginBottom: 0 }}
          rules={[
            () => ({
              validator(rule, value) {
                if (value) {
                  if (value.toLowerCase() !== code.toLowerCase()) {
                    setresetCode(false);
                    setTimeout(() => {
                      setresetCode(true);
                    }, 100);
                    return Promise.reject("校验码错误");
                  }
                  return Promise.resolve();
                }
                return Promise.reject("请输入校验码");
              }
            })
          ]}
        >
          <Input
            placeholder="请输入校验码"
            suffix={
              resetCode && (
                <ImageCode
                  setCode={num => {
                    setCode(num);
                  }}
                />
              )
            }
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="form-button"
            style={{ background: "green !important" }}
            loading={loading}
            disabled={!send || dis}
          >
            {msg}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

/**
 * 发送验证码按钮（新）
 * @param {*} param0
 */
export const CodeNewButton = ({
  send,
  username,
  url,
  params,
  codeType,
  callbackhandleSc,
  callbackhandleSend,
  goTimer,
  renderTo,
  visiblePass
}) => {
  // console.log(send, username, url, params, codeType);
  const [form] = Form.useForm();
  const [dis, setDis] = useState(false);
  const [num, setNum] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [imgUrl, setImageUrl] = useState(``);
  const msg = dis ? `${num}s后重发` : "获取短信验证码";
  // 智能验证状态
  const alibabaScStatus = useRef(false);
  const ScRefParams = useRef({
    sessionId: "",
    sig: "",
    token: ""
  });
  const appkey = "FFFF0N0000000000B190";
  const scene = "ic_register";
  // 重置智能验证
  const resetSc = () => {
    if (window.ic) {
      alibabaScStatus.current = false;
      ScRefParams.current = {
        sessionId: "",
        sig: "",
        token: ""
      };
      window.ic.reset();
    }
  };
  const changesImg = () => {
    document.getElementById(
      "img"
    ).src = `${baseUrl}/customer/fetchShortMessagePreCode?phoneNumber=${username}&timestamp=${new Date().getTime()}`;
  };
  // 获取短信验证码
  const onSubmit = values => {
    console.log(values);
    setLoading(true);
    window.axios
      .get(url || "/customer/code", {
        params: params || {
          username,
          effect: cookies.get("dingzhiBol"),
          codeType,
          preCode: values.code
        }
      })
      .then(({ status, data: { success, message } }) => {
        // console.log('response:', arguments[0])
        if (status !== 200 || !success) {
          showMessage("error", message);
          changesImg();
          form.setFieldsValue({
            code: null
          });
          return;
        }
        showMessage("success", message);
        setNum(60);
        setDis(true);
        form.setFieldsValue({
          code: null
        });
        setVisible(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCancel = () => {
    form.setFieldsValue({
      code: null
    });
    setVisible(false);
  };
  // 获取短信验证码
  const getShortMsg = ({
    appkey,
    scene,
    sessionId,
    sig,
    token,
    mobile,
    codeType
  }) => {
    window.axios
      .get("/alibaba/verificationCode", {
        params: {
          appkey,
          scene,
          mobile,
          sessionId,
          sig,
          token,
          codeType
        }
      })
      .then(res => {
        if (res.data.success) {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      });
  };
  const renderRc = () => {
    AWSCValidate(
      {
        width: "324px",
        appkey,
        scene,
        renderTo: renderTo || "phoneSc",
        mobile: username,
        codeType
      },
      data => {
        // setCodeDisable(false);
        alibabaScStatus.current = true;
        ScRefParams.current = { ...data };
        callbackhandleSc({ appkey, scene, ...data });
        // if (username) {
        //   getShortMsg({
        //     appkey,
        //     scene,
        //     ...data,
        //     mobile: username,
        //     codeType
        //   });
        //   // onGetCode();
        //   setDis(true);
        //   setNum(60);
        // } else {
        //   message.error("请输入正确的手机号码!");
        // }
      }
    );
  };
  // 执行倒计时方法
  const goTimerOut = () => {
    console.log("goTimerOut ");
    setDis(true);
    setNum(60);
  };
  useEffect(() => {
    const fogetPassScDom = document.getElementById("fogetPassSc");
    if (!document.getElementById("SM_BTN_WRAPPER_1")) {
      if (!fogetPassScDom) {
        renderRc();
      }
    }
  }, []);
  useEffect(() => {
    // if (!document.getElementById("SM_BTN_WRAPPER_1")) {
    // if
    if (visiblePass) {
      if (document.getElementById("fogetPassSc").childNodes.length === 0) {
        renderRc();
        // console.log(123);
      }
    }
    // }
  }, [visiblePass]);

  useEffect(() => {
    if (goTimer) {
      goTimerOut();
    }
  }, [goTimer]);
  useEffect(() => {
    let id;
    if (dis) {
      if (num > 0) {
        id = setTimeout(() => {
          setNum(prev => prev - 1);
        }, 1000);
      } else {
        setDis(false);
        setNum(0);
        callbackhandleSend();
        resetSc();
      }
    }
    return () => {
      clearTimeout(id);
    };
  }, [dis, num]);
  return (
    <>
      <Button
        style={{ marginTop: -5 }}
        type="link"
        loading={loading}
        disabled={!send || dis}
        onClick={() => {
          // setNum(60);
          // setDis(true);
          // setVisible(true);
          // setImageUrl(
          //   `${baseUrl}/customer/fetchShortMessagePreCode?phoneNumber=${username}&timestamp=${new Date().getTime()}`
          // );
          if (alibabaScStatus.current) {
            getShortMsg({
              appkey,
              scene,
              ...ScRefParams.current,
              mobile: username,
              codeType
            });
            setNum(60);
            setDis(true);
          } else {
            message.destroy();
            message.error("请点击按钮完成智能验证");
          }
        }}
      >
        {msg}
      </Button>
      {visible ? (
        <Modal
          title="请输入校验码"
          visible={visible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form form={form} onFinish={onSubmit} validateTrigger="onBlur">
            <Form.Item
              name="code"
              style={{ marginBottom: 0 }}
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("校验码不能为空");
                  }
                })
              ]}
              // rules={[
              //   () => ({
              //     validator(rule, value) {
              //       if (value) {
              //         if (value.toLowerCase() !== code.toLowerCase()) {
              //           setresetCode(false);
              //           setTimeout(() => {
              //             setresetCode(true);
              //           }, 100);
              //           return Promise.reject("校验码错误");
              //         }
              //         return Promise.resolve();
              //       }
              //       return Promise.reject("请输入校验码");
              //     }
              //   })
              // ]}
            >
              <Input
                placeholder="请输入校验码"
                suffix={<img id="img" src={imgUrl} alt="" />}
                // suffix={
                //   resetCode && (
                //     <ImageCode
                //       setCode={num => {
                //         setCode(num);
                //       }}
                //     />
                //   )
                // }
              />
            </Form.Item>
            <a style={{ float: "right" }} onClick={changesImg}>
              刷新
            </a>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="form-button"
                style={{ background: "green !important" }}
                loading={loading}
                disabled={!send || dis}
              >
                确认
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

// export CodeNewButton;
/**
 * 发送验证码按钮（旧）
 * @param {*} param0
 */
export function CodeButton({ send, username, url, params, codeType }) {
  console.log(send, username, url, params, codeType);
  const [dis, setDis] = useState(false);
  const [num, setNum] = useState(0);
  const [loading, setLoading] = useState(false);
  const msg = dis ? `${num}s后重发` : "获取验证码";
  useEffect(() => {
    let id;
    if (dis) {
      if (num > 0) {
        id = setTimeout(() => {
          setNum(prev => prev - 1);
        }, 1000);
      } else {
        setDis(false);
        setNum(0);
      }
    }
    return () => {
      clearTimeout(id);
    };
  }, [dis, num]);

  // 发送验证码接口
  function sendCode() {
    console.log(window);
    setLoading(true);
    window.axios
      .get(url || "/customer/code", {
        params: params || {
          username,
          effect: cookies.get("dingzhiBol"),
          codeType
        }
      })
      .then(({ status, data: { success, message } }) => {
        // console.log('response:', arguments[0])
        if (status !== 200 || !success) {
          showMessage("error", message);
          return;
        }
        setNum(60);
        setDis(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Button
      type="link"
      loading={loading}
      disabled={!send || dis}
      onClick={sendCode}
    >
      {msg}
    </Button>
  );
}

// 不能输入非汉字效验  效验不能输入非空字符串
export const validateNoChinese = (rule, value, callback) => {
  const reg = /^[^\u4e00-\u9fa5]+$/g;
  const regEmpty = /^\s*$/g;
  if (value && !reg.test(value)) {
    callback("不能输入汉字");
  } else if (value && regEmpty.test(value)) {
    callback("缺陷编号不能为空");
  } else {
    callback();
  }
};

// 验证是否是纯数字
export const validatePureNumber = value => {
  const reg = /^[0-9]*$/g;
  if (reg.test(value)) {
    return true;
  }
  return false;
};

/**
 * 乘法运算，避免数据相乘小数点后产生多位数和计算精度损失。
 *
 * @param num1被乘数 | num2乘数
 */
export const numMulti = (num1, num2) => {
  let baseNum = 0;
  try {
    baseNum += num1.toString().split(".")[1].length;
  } catch (e) {
    console.log(e);
  }
  try {
    baseNum += num2.toString().split(".")[1].length;
  } catch (e) {
    console.log(e);
  }
  return (
    (Number(num1.toString().replace(".", "")) *
      Number(num2.toString().replace(".", ""))) /
    // eslint-disable-next-line no-restricted-properties
    Math.pow(10, baseNum)
  );
};

// 部门和员工树
// 子部门
export const loopStaffs = (data = []) =>
  data.map(item => {
    if (item.adminRole === 0) {
      return (
        <TreeNode
          key={item.staffId + "." + item.organizationId}
          title={item.staffName}
          value={item.staffId + "." + item.organizationId}
          all={item}
          isLeaf
        />
      );
    }
  });
export const loopEvents = (data = []) =>
  data.map(item => {
    if (item.organizationVOs && item.organizationVOs.length) {
      const childLength =
        (item.staffs ? item.staffs.length : 0) + item.organizationVOs.length;
      return (
        <TreeNode
          key={item.id + "." + item.parentOrgId}
          title={item.name}
          value={item.id + "." + item.parentOrgId}
          all={item}
          isLeaf
          childrenLen={childLength}
        >
          {loopStaffs(item.staffs)}
          {loopEvents(item.organizationVOs)}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        key={item.id + "." + item.parentOrgId}
        title={item.name}
        value={item.id + "." + item.parentOrgId}
        all={item}
        isLeaf
        childrenLen={item.staffNum.length}
      >
        {loopStaffs(item.staffs)}
        {/* {console.log(item)} */}
      </TreeNode>
    );
  });

// export const loopEvents = (data = []) =>
//   data.map(item => {
//     if (item.staffs && item.staffs.length) {
//       return (
//         <TreeNode
//           key={item.staffId}
//           title={item.name}
//           value={item.staffId}
//           all={item}
//         >
//           {loopEvents(item.staffs)}
//         </TreeNode>
//       );
//     }
//     return (
//       <TreeNode
//         key={item.staffId}
//         title={item.name}
//         value={item.staffId}
//         all={item}
//         isLeaf
//         childrenLen={item.staffs ? item.staffs.length : 0}
//       />
//     );
//   });

// export const loopEvents = (data = []) =>
//   data.map(item => {
//     return (
//       <TreeNode key={item.id} title={item.name} value={item.id} all={item}>
//         {(item.staffs || []).map(x => {
//           return (
//             <TreeNode
//               key={x.id}
//               title={x.name}
//               value={x.id}
//               all={x}
//               isLeaf
//             />
//           );
//         })}
//       </TreeNode>
//     );
//   });

// 部门树
export const loopDepEvents = (data = []) =>
  data.map(item => {
    if (item.organizationVOs && item.organizationVOs.length) {
      return (
        <TreeNode key={item.id} title={item.name} value={item.id} all={item}>
          {loopDepEvents(item.organizationVOs)}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        key={item.id}
        title={item.name}
        value={item.id}
        all={item}
        isLeaf
        childrenLen={item.organizationVOs ? item.organizationVOs.length : 0}
      />
    );
  });

// 左侧组织架构树
export const loopOrganTree = (
  data,
  createDep,
  editDep,
  delDep,
  upDep,
  downDep
) =>
  (data.organizationVOs || data || []).map(item => {
    // console.log(data.organizationVOs.length);
    if (item.organizationVOs && item.organizationVOs.length) {
      return (
        <TreeNode
          key={item.id}
          title={
            <div className="hbox" style={{ width: "100%" }}>
              <span>{`${item.name}`}</span>
              <span className="hovers">
                &nbsp;&nbsp;
                {item.level <= 3 ? (
                  <PlusCircleOutlined
                    onClick={() => {
                      createDep(item);
                    }}
                  />
                ) : (
                  ""
                )}
                &nbsp;
                {item.parentOrgId !== 0 ? (
                  <FormOutlined
                    onClick={() => {
                      editDep(item);
                    }}
                  />
                ) : (
                  ""
                )}
                &nbsp;
                {item.parentOrgId !== 0 ? (
                  <DeleteOutlined
                    onClick={() => {
                      delDep(item.id);
                    }}
                  />
                ) : (
                  ""
                )}
                {item.parentOrgId !== 0 &&
                data.organizationVOs[0].id !== item.id ? (
                  <span>
                    &nbsp;
                    <UpCircleOutlined
                      onClick={() => {
                        upDep(item.id);
                      }}
                    />
                  </span>
                ) : (
                  ""
                )}
                &nbsp;
                {item.parentOrgId !== 0 &&
                data.organizationVOs[data.organizationVOs.length - 1].id !==
                  item.id ? (
                    <DownCircleOutlined
                    onClick={() => {
                      downDep(item.id);
                    }}
                  />
                ) : (
                  ""
                )}
              </span>
            </div>
          }
          value={item.id}
          all={item}
        >
          {loopOrganTree(item, createDep, editDep, delDep, upDep, downDep)}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        key={item.id}
        title={
          <div className="hbox" style={{ width: "100%" }}>
            <span>{`${item.name}`}</span>
            <span className="hovers">
              &nbsp;&nbsp;
              {item.level <= 3 ? (
                <PlusCircleOutlined
                  onClick={() => {
                    createDep(item);
                  }}
                />
              ) : (
                ""
              )}
              &nbsp;
              {item.parentOrgId !== 0 ? (
                <FormOutlined
                  onClick={() => {
                    editDep(item);
                  }}
                />
              ) : (
                ""
              )}
              &nbsp;
              {item.parentOrgId !== 0 ? (
                <DeleteOutlined
                  onClick={() => {
                    delDep(item.id);
                  }}
                />
              ) : (
                ""
              )}
              {item.parentOrgId !== 0 &&
              data.organizationVOs[0].id !== item.id ? (
                <span>
                  &nbsp;
                  <UpCircleOutlined
                    onClick={() => {
                      upDep(item.id);
                    }}
                  />
                </span>
              ) : (
                ""
              )}
              &nbsp;
              {item.parentOrgId !== 0 &&
              data.organizationVOs[data.organizationVOs.length - 1].id !==
                item.id ? (
                  <DownCircleOutlined
                  onClick={() => {
                    downDep(item.id);
                  }}
                />
              ) : (
                ""
              )}
            </span>
          </div>
        }
        value={item.id}
        all={item}
        isLeaf
        childrenLen={item.organizationVOs ? item.organizationVOs.length : 0}
      />
    );
  });
//  跳转移动端 （判断是否是移动端）
export const goPhone = page => {
  const ua = navigator.userAgent;

  const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);

  const isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);

  const isAndroid = ua.match(/(Android)\s+([\d.]+)/);

  const isMobile = isIphone || isAndroid;
  if (isMobile) {
    window.location.href = "/phone/" + page;
  }
};
//  获取微信相关信息
export const wxInfo = {
  appid: "wx338f55c8b5822937",
  url: `${BasicUrl.production}/api/customer/auth/bindWeixinWithH5Code?redirect=${BasicUrl.test}/phone/userInfo/security?type=wxbinding&force=true`
  // "https://survey.work/api/customer/auth/bindWeixinWithH5Code?redirect=https://test-front-base.survey.work/phone/userInfo/security?type=wxbinding&force=true"
};
if (process.env.REACT_APP_CURRENT === "test") {
  // wxInfo.api = "http://test.qyconsult.treeyee.com/";  // 微信老版测试接口
  wxInfo.api = BasicUrl.test;
  wxInfo.appid = "wxdec30aa5914743f0";
  wxInfo.url = `${BasicUrl.test}/api/customer/auth?redirect=${BasicUrl.test}/phone/userInfo/security`;
  // "http://test.qyconsult.treeyee.com/api/customer/auth?redirect=https://test-front-base.survey.work/phone/userInfo/security";
} else if (process.env.NODE_ENV === "production") {
  wxInfo.appid = "wx338f55c8b5822937";
  wxInfo.url = `${BasicUrl.production}/api/customer/auth/bindWeixinWithH5Code?redirect=${BasicUrl.production}/phone/userInfo/security`;
  // ("https://survey.work/api/customer/auth/bindWeixinWithH5Code?redirect=https://survey.work/phone/userInfo/security");
}
//  判断是不是特殊域名
export const rightHost = [
  "localhost",
  "test-front-base.survey.work",
  "survey.work"
];
// 时间格式2020-02-11T12:24:18.000+0000转化成正常格式
export const renderTime = date => {
  const dateee = new Date(date).toJSON();
  return new Date(+new Date(dateee) + 8 * 3600 * 1000)
    .toISOString()
    .replace(/T/g, " ")
    .replace(/\.[\d]{3}Z/, "");
};
// 时间格式20200211122418转化成正常格式
export const timeToNormal = date => {
  const dateString = date.toString();
  const pattern = /(\d{4})(\d{2})(\d{2})(\d{2})/;
  return dateString.replace(pattern, "$1-$2-$3 $4:00:00");
};

// 菜单权限树
export const menuAuthorityTree = (data = []) =>
  data.map(item => {
    if (item.menus && item.menus.length) {
      return (
        <TreeNode
          key={item.menuId}
          title={item.name}
          value={item.menuId}
          all={item}
        >
          {menuAuthorityTree(item.menus)}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        key={item.menuId}
        title={item.name}
        value={item.menuId}
        all={item}
        isLeaf
        childrenLen={item.menus ? item.menus.length : 0}
      />
    );
  });
//  获取地址栏全部参数并转换成字符串
export const getUrlAllParam = () => {
  const urlParams = qs.parse(window.location.search);
  console.log(qs.stringify(urlParams));
  return qs.stringify(urlParams);
};
//  超管默认路由
export const mangerPages = [
  {
    path: "/mymymanager/workbench",
    name: "工作台",
    noNav: true,
    children: [
      {
        path: "/mymymanager/workbench/list",
        name: "工作台",
        component: lazy(() => import("pages/manager/workbench"))
      },
      {
        path: "/mymymanager/workbench/setHome",
        name: "设置落地页",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/setHome"))
      },
      {
        path: "/mymymanager/workbench/consult",
        name: "咨询列表",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/consult"))
      },
      {
        path: "/mymymanager/workbench/encyclopedias",
        name: "调研百科",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/encyclopedias"))
      },
      {
        path: "/mymymanager/workbench/authentication",
        name: "认证审核",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/authentication"))
      },
      {
        path: "/mymymanager/workbench/new",
        name: "新闻资讯",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/news"))
      },
      {
        path: "/mymymanager/workbench/cooperation",
        name: "合作页文案",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/cooperation"))
      },
      {
        path: "/mymymanager/workbench/faq",
        name: "FAQ",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/faq"))
      },
      {
        path: "/mymymanager/workbench/vip",
        name: "VIP文案",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/vip"))
      },
      {
        path: "/mymymanager/workbench/busData",
        name: "运营数据",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/businessData"))
      },
      {
        path: "/mymymanager/workbench/serviceDomain",
        name: "服务域名管理",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/serviceDomain"))
      },
      {
        path: "/mymymanager/workbench/samplePush",
        name: "样本消息推送",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/samplePush"))
      },
      {
        path: "/mymymanager/workbench/wxMessage",
        name: "微信公众号消息推送",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/wxMessage"))
      },
      {
        path: "/mymymanager/workbench/estimate",
        name: "交易评价",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/estimate"))
      },
      {
        path: "/mymymanager/workbench/chatadmin",
        name: "聊天记录管理",
        noNav: true,
        component: lazy(() => import("pages/manager/workbench/chatAdmin"))
      },
      {
        path: "/mymymanager/workbench/bankAuthentication",
        name: "银行审核",
        noNav: true,
        component: lazy(() =>
          import("pages/manager/workbench/bankAuthentication")
        )
      }
    ]
  },
  {
    path: "/mymymanager/user",
    name: "用户管理",
    noNav: true,
    children: [
      {
        path: "/mymymanager/user/list",
        name: "平台注册用户",
        noNav: true,
        component: lazy(() => import("pages/manager/user/userList"))
      },
      {
        path: "/mymymanager/user/regtest",
        name: "注册检测",
        component: lazy(() => import("pages/manager/user/regtest"))
      },
      {
        path: "/mymymanager/user/appSet",
        name: "App配置",
        component: lazy(() => import("pages/manager/user/appset"))
      },
      {
        path: "/mymymanager/user/baidu-statistics",
        name: "业务客户",
        component: lazy(() => import("pages/manager/user/baidu-statistics"))
      }
    ]
  },
  {
    path: "/mymymanager/company",
    name: "企业管理",
    noNav: true,
    children: [
      {
        path: "/mymymanager/company/list",
        name: "企业列表",
        noNav: true,
        component: lazy(() => import("pages/manager/company/companyList"))
      },
      {
        path: "/mymymanager/company/set",
        name: "企业设置",
        noNav: true,
        component: lazy(() => import("pages/manager/company/companyList/set"))
      },
      {
        path: "/mymymanager/company/config",
        name: "换壳配置",
        noNav: true,
        component: lazy(() =>
          import("pages/manager/company/companyList/config")
        )
      },
      {
        path: "/mymymanager/company/exchangeshell",
        name: "企业通用换壳",
        noNav: false,
        component: lazy(() =>
          import("pages/manager/company/companyList/exchangeShell")
        )
      }
    ]
  },
  {
    path: "/mymymanager/pay",
    name: "支付管理",
    noNav: true,
    children: [
      {
        path: "/mymymanager/pay/list",
        name: "平台金额统计",
        noNav: true,
        component: lazy(() => import("pages/manager/pay/payList"))
      },
      {
        path: "/mymymanager/pay/tradelist",
        name: "平台交易记录",
        noNav: true,
        component: lazy(() => import("pages/manager/pay/tradeList"))
      }
    ]
  },
  {
    path: "/mymymanager/sys",
    name: "平台管理",
    noNav: true,
    children: [
      {
        path: "/mymymanager/sys/user",
        name: "用户列表",
        noNav: true,
        component: lazy(() => import("pages/manager/sys/userList"))
      },
      {
        path: "/mymymanager/sys/role",
        name: "角色列表",
        noNav: true,
        component: lazy(() => import("pages/manager/sys/roleList"))
      }
    ]
  }
];
//  富文本基本配置
export const controls = [
  "font-size",
  "font-family",
  "line-height",
  "letter-spacing",
  "text-color",
  "bold",
  "italic",
  "underline",
  "text-color",
  "separator",
  "link",
  "strike-through",
  "superscript",
  "subscript",
  "emoji",
  "separator",
  "media"
];
// 硬编  如果这个域名登录成功 不进入工作台，直接跳到培训吧
export const goTrain = (token, companyid) => {
  if (window.location.hostname === "base.jyfw.survey.work" && companyid) {
    window.location.href = `http://px.jyfw.survey.work?token=${token}&companyid=${companyid}&t=${t}&role=manager`;
  }
};
// 验证区

//  手机号验证  何新彪让验证  只验证11 位，因为有的号不是正常手机号
export const phoneReg = /^\d{11}$/;
// export const phoneReg = /^1[3456789]\d{9}$/;
//  银行卡号验证
export const bandCardReg = /^([1-9]{1})(\d{15}|\d{18})$/;
// 方舟版本可见手机号
export const fangPhoneArr = [
  "31234590006",
  "31234590007",
  "31234590008",
  "31234590009",
  "31234590010",
  "31234590011",
  "31234590012",
  "31234590013",
  "31234590014",
  "31234590015",
  "31234590016",
  "31234590017",
  "31234590018",
  "31234590019",
  "31234590020",
  "31234590021",
  "31234590022"
];
// 方舟子账号控制
export const fangZiArr = ["260", "261"];

/*
 * fn [function] 需要防抖的函数
 * delay [number] 毫秒，防抖期限值
 */
export const debounce = (fn, delay) => {
  let timer = null; // 借助闭包
  return function() {
    if (timer) {
      clearTimeout(timer); // 进入该分支语句，说明当前正在一个计时过程中，并且又触发了相同事件。所以要取消当前的计时，重新开始计时
      timer = setTimeout(fn, delay);
    } else {
      timer = setTimeout(fn, delay); // 进入该分支说明当前并没有在计时，那么就开始一个计时
    }
  };
};

// rgb转换成16进制
export const rgb2hex = (sRGB = "") => {
  sRGB = sRGB.replace(/ /g, ""); //  去掉多余空格

  // 正则表达式，使用()，
  // 当调用string.match方法是，数组1/2/3分别对应匹配值
  const reg = /^rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)$/i;
  const match = sRGB.match(reg);
  if (!match) return sRGB; // 非法，直接返回

  const numList = [];
  for (let i = 1; i < 4; i++) {
    // eslint-disable-next-line radix
    const num = parseInt(match[i]);
    if (num > 255) return sRGB; // >255，非法，直接返回
    numList.push(num);
  }
  // eslint-disable-next-line func-names
  const ret = numList.reduce(function(pre, cur) {
    let nCur = cur.toString(16);
    if (nCur.length === 1) nCur = "0" + nCur;

    return pre + nCur;
  }, "#");
  return ret;
};
